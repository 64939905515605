






















































































































































































































































































import Vue from "vue";

// Typescript Files
import * as Api from "@/axios-generated/backoffice/index";

import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { required } from "@validations";
import useProductsEdit from "./useProductsEdit";

export default Vue.extend({
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
  },
  data() {
    return {
      required,
      publicPath: process.env.BASE_URL,
      categoryOptions: [
        { value: "select_value", text: "Select Value" },
        {
          value: Api.ProductCategoryEnum.Saas,
          text: Api.ProductCategoryEnum.Saas,
        },
        {
          value: Api.ProductCategoryEnum.Api,
          text: Api.ProductCategoryEnum.Api,
        },
      ],
      statusOptions: [
        { value: "select_value", text: "Select Value" },
        {
          value: Api.ProductStatusEnum.Created,
          text: Api.ProductStatusEnum.Created,
        },
        {
          value: Api.ProductStatusEnum.Enabled,
          text: Api.ProductStatusEnum.Enabled,
        },
        {
          value: Api.ProductStatusEnum.Suspended,
          text: Api.ProductStatusEnum.Suspended,
        },
        {
          value: Api.ProductStatusEnum.Disabled,
          text: Api.ProductStatusEnum.Disabled,
        },
      ],
    };
  },
  props: {
    editProduct: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { updateProduct } = useProductsEdit();

    const productImageOptions = require
      .context("/public/images/products", true, /^.*\.png$/)
      .keys()
      .map((val) => val.replace("./", ""));

    return {
      updateProduct,
      productImageOptions,
    };
  },
  methods: {
    submitForm(editProduct) {
      this.$bvModal
        .msgBoxConfirm("Voulez-vous confirmer l'opération ?", {
          size: "sm",
          okVariant: "primary",
          okTitle: "Oui",
          cancelTitle: "Non",
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.updateProduct(editProduct);
          }
        });
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.accountRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormInfo() {
      return new Promise((resolve, reject) => {
        this.$refs.infoRules.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
  },
});
