<template>
  <b-row class="mt-1">
    <b-col cols="12">
      <product-edit-form-wizard :edit-product="editProduct" />
    </b-col>
  </b-row>
</template>

<script>
import { PageBoxed } from "@/views/utils/PageBoxed.js";
import { useRouter } from "@core/utils/utils";
import { ref, onUnmounted } from "@vue/composition-api";
import { BRow, BCol } from "bootstrap-vue";
import ProductEditFormWizard from "./ProductEditFormWizard.vue";
import useProductsEdit from "./useProductsEdit";
import productStoreModule from "../productStoreModule";
import store from "@/store";

export default {
  components: {
    BRow,
    BCol,
    ProductEditFormWizard,
  },
  mixins: [PageBoxed],
  setup() {
    const editProduct = ref({});

    const PRODUCT_APP_STORE_MODULE_NAME = "app-product";

    const { fetchProductByID } = useProductsEdit();

    // Pull productID from router
    const { route } = useRouter();
    const productID = route.value.params.id;

    // Register module
    if (!store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
      store.registerModule(PRODUCT_APP_STORE_MODULE_NAME, productStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_APP_STORE_MODULE_NAME))
        store.unregisterModule(PRODUCT_APP_STORE_MODULE_NAME);
    });

    editProduct.value.ID = productID;
    fetchProductByID(editProduct);

    return {
      editProduct,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
